import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { FormKind, initFormController } from '@wix/form-viewer/controller';
import settingsParams from './settingsParams';
import { isTemplateForm } from './utils/templates';
import { NAMESPACE } from '../../constants/namespace';
import { createEventHandler } from '@wix/tpa-settings';
import { SettingsEvents, SettingsEventsKeys } from '../../constants/settings';
import { ForcedState } from '@wix/form-viewer';
import { FORM_TEMPLATES, FormAppPreset } from '../../constants/templates';
import { isFormsLimitReached } from '../../editor-script/utils';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerConfig.config.publicData.COMPONENT || {},
  );

  return {
    pageReady: async () => {
      const formId = flowAPI.settings.get(settingsParams.formId) as string;
      const templateForm = isTemplateForm(formId);
      const presetId = controllerConfig.config.publicData.COMPONENT.presetId;
      const newlyAddedTemplateForm =
        Object.keys(FORM_TEMPLATES).includes(presetId) && formId === '';
      const shouldCheckIfFormLimitReached = !(
        flowAPI.environment.isViewer || flowAPI.environment.isPreview
      );

      // set loading initially
      controllerConfig.setProps({
        fitToContentHeight: true,
        loading: true,
      });

      try {
        const formLimitReached = shouldCheckIfFormLimitReached
          ? await isFormsLimitReached(flowAPI.httpClient)
          : false;

        const shouldShowEmptyState =
          ([FormAppPreset.Blank, FormAppPreset.Existing, undefined].includes(
            presetId,
          ) &&
            formId === '') ||
          (newlyAddedTemplateForm && formLimitReached);

        if (shouldShowEmptyState) {
          controllerConfig.setProps({
            fitToContentHeight: true,
            loading: false,
          });
        } else {
          if (formId) {
            const { hasForm } = await initFormController(flowAPI, {
              formId,
              formKind: templateForm ? FormKind.EXTENSION : undefined,
              namespace: NAMESPACE,
              throwWhenFormMissing: false,
              useWarmupData: true,
              useListInsteadQuery: true,
            });

            if (hasForm(formId)) {
              controllerConfig.setProps({
                formId,
                loading: false,
              });
            } else {
              controllerConfig.setProps({
                loading: false,
                formDeleted: true,
              });
            }
          }
        }
      } catch (e) {
        // if error was encountered, set loading false, widget should show empty state
        console.log(e);
        controllerConfig.setProps({
          fitToContentHeight: true,
          loading: false,
        });
      }

      componentEventHandler.on(
        SettingsEventsKeys.ForceView,
        (forceView: ForcedState) =>
          controllerConfig.setProps({
            forceView,
          }),
      );
    },
    updateConfig: async (_, updatedConfig) => {
      const formId = updatedConfig?.publicData?.COMPONENT?.formId;
      const templateForm = isTemplateForm(formId);

      componentEventHandler.notify(updatedConfig.publicData.COMPONENT || {});

      if (formId) {
        await initFormController(flowAPI, {
          formId,
          formKind: templateForm ? FormKind.EXTENSION : undefined,
          namespace: NAMESPACE,
        });

        controllerConfig.setProps({
          fitToContentHeight: true,
          formId,
          loading: false,
        });
      }
    },
  };
};

export default createController;
