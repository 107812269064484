export const FORM_TEMPLATES = {
  contactFormApp: 'dd95e41e-ee00-4bdd-bc37-b1d7ca56330e',
  contactFormAppDesign: 'dd95e41e-ee00-4bdd-bc37-b1d7ca56330e',
  contactFormAppStudioDesign: '77c927f7-e811-4023-a40e-24f83a3fcea6',
  subscribeFormApp: '240c319f-08f5-438b-8782-623c2da2f4ac',
  subscribeFormAppDesign: '240c319f-08f5-438b-8782-623c2da2f4ac',
};

export const FORM_TEMPLATE_NAMES = {
  [FORM_TEMPLATES.contactFormApp]: 'Contact us',
  [FORM_TEMPLATES.contactFormAppDesign]: 'Contact us',
  [FORM_TEMPLATES.contactFormAppStudioDesign]: 'Contact us',
  [FORM_TEMPLATES.subscribeFormApp]: 'Subscribe',
  [FORM_TEMPLATES.subscribeFormAppDesign]: 'Subscribe',
};

export enum FormAppPreset {
  Blank = 'blankFormApp',
  Existing = 'existingFormApp',
}
